export default function getCustomHeaders(headersToInclude) {
  const customHeaders = {};
  if (headersToInclude) {
    const urlSchema = new URLSearchParams(location.search);

    for (let key of Object.keys(headersToInclude)) {
      const queryParamName = headersToInclude[key];
      let value = urlSchema.get(queryParamName);

      if (value) {
        if (key === 'Authorization' && !value.startsWith('Bearer'))
          value = `Bearer ${value}`;

        customHeaders[key] = value;
      }
    }
  }

  return customHeaders;
}
